import Api from '@/utils/axios/Api';
const Http = Api.getInstance();





export const CentersCreate = (payload:Object) => {
    return new Promise((resolve, reject) => {
        Http.post(`centers/` , payload)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}
export const CentersUpdate = (id:number , payload:Object) => {
    return new Promise((resolve, reject) => {
        Http.put(`centers/${id}` , payload)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}
export const CentersFind = (id:number) => {
    return new Promise((resolve, reject) => {
        Http.get(`centers/${id}`)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}

